import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

// Move flex direction to Column
// Remove grey box and make text font Lato
// Center Align Everything
// Fix Footer

const CostumeContainer = styled.div`
  flex: 1;
  text-align: center;
`

const CostumeTextSection = styled.div`
  color: #666;
  font-size: 1.6em;
  text-align: center;
  padding: 50px 20vw 50px 20vw;
`

const ReturnLink = styled(Link)`
  text-decoration: none;
  transition: color 0.2s;
  &:hover {
    color: #006666;
  }
`

const Gallery = styled.div`
  padding: 20px;
`

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout header={frontmatter.name}>
      <CostumeContainer>
        <CostumeTextSection dangerouslySetInnerHTML={{ __html: html }} />
        <ReturnLink style={{ color: "#666" }} to="/costumes">
          Back to Costumes Page
        </ReturnLink>
        <Gallery>
          {frontmatter.gallery.map(image => {
            const img = getImage(image)
            return (
              <GatsbyImage
                style={{ margin: "20px" }}
                image={img}
                alt={frontmatter.name}
              />
            )
          })}
        </Gallery>
      </CostumeContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        name
        gallery {
          childImageSharp {
            gatsbyImageData(
              height: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              transformOptions: { cropFocus: CENTER, fit: COVER }
            )
          }
        }
      }
    }
  }
`
